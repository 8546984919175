<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Suppression d'un token</h2>
		</div>
		<h2>
			Principe de base</h2>
		Lorsque qu'un token a un solde nul, on peut le supprimer de la liste des tokens actifs autorisés.
		C'est une protection utile contre les erreurs de transfert.
		<p>

		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Loïc à revendu tous des tokens RE59000F001, il ne pourra pas en racheter avant un certain temps et souhaite le supprimer.
			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/delete_asset-1.png" />
				<br /> <br /> <b> Le compte de Loïc</b><br />
			</div>

		</div>


		<h3 class="mt-12">
			Suppression du token</h3>


		<p>Dans la section <b>Vente</b>.</p>


		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/delete_asset-2.png" />
				<br /> <br />
				<b>Loïc sélectionne le menu "Vente", la liste des tokens dont il dispose s'affiche
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/delete_asset-3.png" /><br />
				<br /> <br />
				<b> Loïc sélectionne le token RE59000F001 à supprimer, le menu de suppression s'affiche
				</b> <br />
			</div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/delete_asset-4.png" /><br />
				<br /> <br />
				<b> Loïc saisie sont code de sécurité et valide
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/delete_asset-5.png" /><br />
				<br /> <br />
				<b> Le compte de Loïc après la transaction. Le token RE59000F001 n'apparait plus.
				</b> <br />
			</div>


		</div>



		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Loïc a payé 0.00001 XLM (ici à 0.20 euro pour 1 XLM ) pour l'ordre de suppression
				<br /> <br /> Au final moins de 1 centime d'euro.
			</p>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
